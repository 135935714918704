import React, { useEffect } from "react"
import { firestore } from "../../utils/firebase"
import { collection, getDocs } from "firebase/firestore"

const KEYS = [
  "timestamp",
  "userId",
  "symptoms%tired",
  "symptoms%concentration",
  "symptoms%headache",
  "symptoms%mood",
  "cbd",
  "taste",
  "shipping",
  "cbd_effect",
  "quality",
  "price",
  "sex",
  "age",
]

const getKey = (key, detail = false) => {
  const idx = key.indexOf("%")
  if (idx > -1) {
    return detail ? key.substring(idx + 1) : key.substring(0, idx)
  }
  return key
}

const getValue = (row, key) => {
  const questionKey = getKey(key)
  const value = row[questionKey]

  if (Array.isArray(value) && questionKey !== key) {
    const valueKey = getKey(key, true)
    return value.includes(valueKey) ? 1 : 0
  }

  return value
}

const Results = () => {
  const [results, setResults] = React.useState([])

  useEffect(() => {
    const getAllDocs = async () => {
      const docs = await getDocs(collection(firestore, "fragebogen"))
      const result = []
      docs.forEach(doc => {
        result.push(doc.data())
      })

      setResults(result)
    }

    getAllDocs()
  }, [])

  return (
    <div>
      <pre>{KEYS.map(k => getKey(k, true)).join("\t")}</pre>
      {results.map((r, i) => (
        <pre key={i}>{KEYS.map(key => getValue(r, key)).join("\t")}</pre>
      ))}
    </div>
  )
}

export default Results
